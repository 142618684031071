<template>
  <div class="mt-4" v-if="!fetchingData">
    <v-row>
      <v-col cols="12">
        <StateTogglesCard
          :data="data"
          :showLost="false"
          @updated="$emit('updated')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <LeadBaseData :data="data"></LeadBaseData>
        <LeadFeedbacks :id="data.id" class="mt-5" />
      </v-col>

      <v-col v-if="data.pay && data.pay.type === 'contract'" cols="12" md="6">
        <LeadContractCard :data="data" @orderTerminated="orderTerminated()" />
        <LeadPaymentCard :data="data" class="mt-5" />
      </v-col>

      <v-col v-if="data.pay && data.pay.type === 'product'" cols="12" md="6">
        <LeadProductsCard :data="data" />
        <LeadPaymentCard :data="data" class="mt-5" />
      </v-col>

      <v-col cols="12" md="6">
        <LeadFunnelStats :id="data.id" />
      </v-col>

      <v-col cols="12" md="6">
        <v-card v-if="!fetchingData" class="align-self-stretch" height="100%">
          <v-toolbar dense dark color="dark">
            <v-toolbar-title>Notizen</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <ActivityNotes :leadId="data.id" />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="identityInfo" cols="12" md="12">
        <IdentityHistory
          :name="rando"
          :identityUuid="data.identityUuid"
          :motherLeadId="data.id"
          :leadName="data.firstName + ' ' + data.lastName"
          :key="componentKey"
        />
      </v-col>

      <!-- <pre>{{ data }}</pre> -->
    </v-row>
  </div>
  <div v-else>
    <v-row class="my-6">
      <v-col cols="12" md="6">
        <v-skeleton-loader
          type="list-item-avatar-three-line, image, article"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="6">
        <v-skeleton-loader
          type="list-item-avatar-three-line, image, article"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader
          type="list-item-avatar-three-line, image, article"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import LeadBaseData from "@/components/lead/manage/LeadBaseData";
/* import LeadBaseDataCard from "@/components/lead/manage/LeadBaseDataCard";
import LeadActivity from "@/components/lead/manage/LeadActivity";
import LeadAdditionalData from "@/components/lead/manage/LeadAdditionalData"; */
import StateTogglesCard from "@/components/lead/manage/StateTogglesCard";
import LeadFeedbacks from "@/components/lead/manage/LeadFeedbacks";
import LeadContractCard from "@/components/lead/manage/LeadContractCard";
import LeadPaymentCard from "@/components/lead/manage/LeadPaymentCard";
import LeadProductsCard from "@/components/lead/manage/LeadProductsCard";
import LeadFunnelStats from "@/components/lead/manage/LeadFunnelStats";
import ActivityNotes from "@/components/lead/manage/ActivityNotes";
export default {
  name: "LeadDetailView",

  components: {
    LeadBaseData,
    /* LeadBaseDataCard,
    LeadAdditionalData, */
    LeadFeedbacks,
    LeadContractCard,
    LeadPaymentCard,
    LeadProductsCard,
    LeadFunnelStats,
    StateTogglesCard,
    ActivityNotes,
    IdentityHistory: () => import("@/components/lead/manage/IdentityHistory"),
  },

  props: {
    leadId: {
      type: String,
      required: true,
    },
    context: {
      type: String,
      required: false,
      default: "leads",
    },
    type: {
      // member or shop (vorrübergehend)
      type: String,
      required: false,
      default: "member",
    },
    identityInfo: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      rando: `IdentityHistory${Math.random().toString(36).substring(2, 10)}`,
      pageTitle: null,
      pageDescription: null,
      fetchingData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      componentKey: 0,
      data: [], // wrapper for fetched data
    };
  },

  created: function () {
    this.getData();
  },

  mounted() {
    this.componentKey += 1;
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/${this.type}Leads/${this.leadId}`
      );
      this.fetchingData = false;
      this.data = response.data.data;
    },
    orderDeleted() {
      this.$emit("orderDeleted", true);
    },
    orderTerminated() {
      this.$emit("orderTerminated", true);
    },
    generateRandomString() {
      return (
        Math.random().toString(36).substring(2, 10) +
        Math.random().toString(36).substring(2, 10)
      );
    },
  },
  mixins: [manageApi],
};
</script>
